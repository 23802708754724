import React, { useState } from 'react';
import './studentProgressChart.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

library.add( faChevronRight, faChevronLeft );

const StudentProgressChart = ({ 
    studentsData, 
    objectivesData, 
    getColorCode, 
    handleSelectStudent
    }) => {

    const [chunkNum, setChunkNum] = useState(0);

    const chunks = (array, chunkSize) => {
        const result = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            result.push(chunk);
        }

        return result;
    }

    const chunkedArray = chunks(studentsData, 5);

    const iterateStudentChunk = (array, direction) => {
        if (direction === 'up') {
            if (chunkNum + 1 > array.length - 1) {
                setChunkNum(0);
            } else {
                setChunkNum(chunkNum + 1);
            }
        } else {
            if (chunkNum - 1 < 0) {
                setChunkNum(array.length - 1);
            } else {
                setChunkNum(chunkNum - 1);
            }
        }
    }
    
    return (
        <div className="studentProgressChart">
            <div className='tileHeading'>
                <h2>Student Progress</h2>
                <div className='chunkButtons'>
                    <button onClick={(e) => iterateStudentChunk(chunkedArray, 'down')}><FontAwesomeIcon className='chevronArrows' icon="fa-solid fa-chevron-left" /></button>
                    <p>{chunkNum + 1}</p>
                    <button onClick={(e) => iterateStudentChunk(chunkedArray, 'up')}><FontAwesomeIcon className='chevronArrows' icon="fa-solid fa-chevron-right" /></button>
                </div>
            </div>
            <p style={{fontSize: '12px', margin: '0 auto 5px auto', position: 'sticky', left: '50%'}}>(Objective Numbers)</p>
            <section className="chart-table">
                <div className='tableHeadings'>
                    {objectivesData.map(objective => (
                            <p key={objective.objectiveNum}>{objective.objectiveNum}</p>
                    ))}
                </div>
                <div className='chartArea'>
                    {chunkedArray[chunkNum].map(student => (
                        <div className='studentRow' key={student.firstName} style={{display: 'flex'}}>
                            <th className="studentNameButton" scope="row">
                                <button onClick={() => { handleSelectStudent(student) }}>{`${student.firstName} ${student.lastName}`}</button>
                            </th>
                            <div className='objectiveRow'>
                            {objectivesData.map(objective => (
                                <td className='objectiveTile' key={`${student.firstName}-${objective.objectiveNum}`} style={{ backgroundColor: getColorCode(student.currentObjective, objective.objectiveNum) }}></td>
                            ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default StudentProgressChart;
