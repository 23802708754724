import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase";
import { collection, getDocs, updateDoc, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import './teacher.css';

import StudentProgressChart from './StudentProgressChart.jsx';
import AddStudent from './addStudent.jsx';
import StudentPopup from './StudentPopup.jsx';
import ClassStats from './classStats.jsx';
import QuickData from './quickData.jsx';


const TeacherFetch = ({
    teacherDocumentID = '',
    firstName
}) => {
    const [studentsData, setStudentsData] = useState(null);
    const [curriculumName, setCurriculumName] = useState(null);
    const [requiredPassesToProgress, setRequiredPassesToProgress] = useState(null);
    const [objectivesData, setObjectivesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [lessonObjectiveData, setLessonObjectiveData] = useState(null);
    const [graded, setGraded] = useState(false); // Track graded state
    const [currentObjective, setCurrentObjective] = useState(null); // Add currentObjective state
    const [lessonsData, setLessonsData] = useState([]);
    const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [selectedLessonId, setSelectedLessonId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [notes, setNotes] = useState('');
    const [erroredWords, setErroredWords] = useState([]);
    const [percentageCorrect, setPercentageCorrect] = useState(1);
    const [studentCount, setStudentCount] = useState();
    const [unitScores, setUnitScores] = useState();
    const [unitNames, setUnitNames] = useState();



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch curriculum name from teacher document
                const teacherDocRef = doc(firestore, 'users', `${teacherDocumentID}`);
                const teacherDocSnapshot = await getDoc(teacherDocRef);
        
                if (teacherDocSnapshot.exists()) {
                    const teacherData = teacherDocSnapshot.data();
                    const curriculumName = teacherData.curriculumName;
                    const requiredPassesToProgress = teacherData.requiredPassesToProgress;
                    setCurriculumName(curriculumName);
                    setRequiredPassesToProgress(requiredPassesToProgress);
                    console.log(curriculumName);
                    console.log(requiredPassesToProgress);
        
                    if (curriculumName) {
                        // Fetch students data
                        const studentsCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students');
                        const studentsSnapshot = await getDocs(studentsCollectionRef);
                        const studentsData = studentsSnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }));
                        
                        if(studentsData) {
                        if (studentsData.length >= 1) {
                                const sortedStudents = studentsData.sort((a, b) => a.firstName.localeCompare(b.firstName));
                                setStudentsData(sortedStudents);
                                setStudentCount(sortedStudents);
                                
                        }}
                        
                        // Calculate the highest current objective and update the teacher document
                        const highestCurrentObjective = Math.max(...studentsData.map(student => student.currentObjective || 0));
                        await updateDoc(teacherDocRef, { 
                            highestCurrentObjective: highestCurrentObjective
                        });

        
                        // Fetch objectives data
                        const objectivesCollectionRef = collection(firestore, 'curriculums', curriculumName, 'objectives');
                        const objectivesSnapshot = await getDocs(objectivesCollectionRef);
                        const objectivesData = objectivesSnapshot.docs.map(doc => doc.data());
                        const sortedObjectives = objectivesData.sort((a, b) => a.objectiveNum - b.objectiveNum);
                        setObjectivesData(sortedObjectives);
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error);
                setLoading(false);
            }
        };
        
        

        fetchData();
        fetchClassLessonScores(teacherDocumentID);
    }, [teacherDocumentID]);

    useEffect(() => {
        if (selectedStudent && selectedObjective !== null) {
            fetchLessonData(selectedObjective, selectedStudent.id);
        }
    }, [selectedStudent, selectedObjective]);


    useEffect(() => {
        if (selectedStudent) {
            const studentDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${selectedStudent.id}`);
            const unsubscribe = onSnapshot(studentDocRef, (doc) => {
                const studentData = doc.data();
                if (studentData) {
                    setCurrentObjective(studentData.currentObjective);
                }
            });
            return () => unsubscribe();
        }
    }, [selectedStudent]);

    //fetches student lesson score information
    let names = [];
    let scores = [];
    const fetchClassLessonScores = async (teacherDocumentID) => {
        const studentCollection = collection(firestore, 'users', `${teacherDocumentID}`, 'students');
        const studentCollectionSnapshot = await getDocs(studentCollection);
        studentCollectionSnapshot.forEach( async (collectionDocs) => {
            let documentName = collectionDocs.id;
            let singleStudentObjectiveCollection = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${documentName}`, 'objectives');
            let singleStudentObjectivesSnapshot = await getDocs(singleStudentObjectiveCollection);
            singleStudentObjectivesSnapshot.forEach(async (objectiveDocs) => {
                let objectiveDocsName = objectiveDocs.id;

                if (names.includes(objectiveDocsName)){
                    // do nothing
                } else {
                    names.push(objectiveDocsName.toString());
                }

                let individualLessons = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${documentName}`, 'objectives', `${objectiveDocsName}`, 'lessons');
                let individualLessonSnapshot = await getDocs(individualLessons);
                individualLessonSnapshot.forEach((lesson) => {
                    let individualLessonData = lesson.data();
                    let lessonID = lesson.id;
                    // create object that holds a key which is the lessonID (objective1). and the values are all scores on that lessonID 
                    scores.push(individualLessonData.score);
                })
            })
        })
        
        setUnitNames(names);
        setUnitScores(scores);
        console.log('unitnames', unitNames);
    }
    
    // fetches class level lesson data
    const fetchStudentLessonData = async (student) => {
        try {
            // Fetch Student Lesson data
            const studentObjectiveCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${student}`, 'objectives');
            const objectiveLessonSnapshot = await getDocs(studentObjectiveCollectionRef);
            const objectiveDocsData = objectiveLessonSnapshot.docs.map(doc => doc.data()); // Renamed variable for clarity
            setLessonObjectiveData(objectiveDocsData);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    const fetchLessonData = async (objectiveNum, student) => {
        try {
            const lessonsCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${student}`, 'objectives', `objective${objectiveNum}`, 'lessons');
            const lessonsSnapshot = await getDocs(lessonsCollectionRef);
            
            // Fetch each lesson with its errors and notes
            let lessonsData = await Promise.all(lessonsSnapshot.docs.map(async (doc) => {
                const lessonData = { id: doc.id, ...doc.data() };
    
                // Fetch errors subcollection
                const errorsCollectionRef = collection(doc.ref, 'errors');
                const errorsSnapshot = await getDocs(errorsCollectionRef);
                const errorsData = errorsSnapshot.docs.flatMap(errorDoc => 
                    errorDoc.data().wholeWordErrors.map(error => ({
                        index: error.index,
                        normalized: error.normalized,
                        saidInstead: error.saidInstead,
                        word: error.word
                    }))
                );
                
                // Fetch notes subcollection
                const notesCollectionRef = collection(doc.ref, 'notes');
                const notesSnapshot = await getDocs(notesCollectionRef);
                const notesData = notesSnapshot.docs.map(noteDoc => noteDoc.data().notes).join('\n');  // Combine notes if there are multiple
                
                const graded = lessonData.graded || false;
                return {
                    ...lessonData,
                    erroredWords: errorsData,
                    notes: notesData,
                    graded: graded
                };
            }));
    
            // Sort lessonsData alphanumerically based on lesson ID
            lessonsData.sort((a, b) => {
                const lessonIdA = parseInt(a.id.replace('lesson', '')); // Extract the lesson number from the ID
                const lessonIdB = parseInt(b.id.replace('lesson', '')); // Extract the lesson number from the ID
                return lessonIdA - lessonIdB;
            });
    
            if (lessonsData.length > 0) {
                setLessonsData(lessonsData);
                setCurrentLessonIndex(0); // Reset current lesson index
                setSelectedLessonId(lessonsData[0].id); // Select the first lesson
                displayLesson(lessonsData[0]); // Display the first lesson
            } else {
                // No lessons found for the selected objective
                setLessonsData([]);
                setCurrentLessonIndex(0);
                setSelectedLessonId(null);
            }
        } catch (error) {
            console.error("Failed to fetch lesson data:", error);
        }
    };
    
    

    
    // Displays individual lesson data
    function displayLesson(lesson) {
        return;
    }
    
    
    function nextLesson() {
        if (currentLessonIndex < lessonsData.length - 1) {
            const newIndex = currentLessonIndex + 1;
            setCurrentLessonIndex(newIndex); // Update the current lesson index
            setSelectedLessonId(lessonsData[newIndex].id); // Update the selected lesson ID
            displayLesson(lessonsData[newIndex]); // Display the new lesson
            setIsEditing(false);
        }
    }
    
    function previousLesson() {
        if (currentLessonIndex > 0) {
            const newIndex = currentLessonIndex - 1;
            setCurrentLessonIndex(newIndex); // Update the current lesson index
            setSelectedLessonId(lessonsData[newIndex].id); // Update the selected lesson ID
            displayLesson(lessonsData[newIndex]); // Display the new lesson
            setIsEditing(false);
        }
    }
    
    
    const handleSaveLessonChangesClick = () => {
        if (selectedStudent && selectedObjective !== null && selectedLessonId) {
            handleSaveLessonChanges(
                selectedStudent.id,
                selectedObjective,
                selectedLessonId,
                erroredWords,
                notes,
                graded
            );
            setIsEditing(false);
        }
    };



    const handleSaveLessonChanges = async (studentDocumentID, objectiveNum, lessonID, erroredWords, notes, graded) => {
        try {
            // Reference to the lesson document
            const lessonDocumentRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${objectiveNum}`, 'lessons', lessonID);
            
        // Update the lesson document with the graded field
            await updateDoc(lessonDocumentRef, {
                graded: true, // Set the graded field to true
                score: percentageCorrect
                
            });

            // Reference to the errors and notes subcollections
            const errorsCollectionRef = collection(lessonDocumentRef, 'errors');
            const notesCollectionRef = collection(lessonDocumentRef, 'notes');
            
            // Fetch existing documents in the errors subcollection
            const errorsSnapshot = await getDocs(errorsCollectionRef);
            if (errorsSnapshot.empty) {
                // No existing documents, create a new document
                await setDoc(doc(errorsCollectionRef), {
                    wholeWordErrors: erroredWords
                });
            } else {
                // Update the first existing document
                const existingErrorDoc = errorsSnapshot.docs[0];
                await updateDoc(existingErrorDoc.ref, {
                    wholeWordErrors: erroredWords
                });
            }
            
            // Fetch existing documents in the notes subcollection
            const notesSnapshot = await getDocs(notesCollectionRef);
            if (notesSnapshot.empty) {
                // No existing documents, create a new document
                await setDoc(doc(notesCollectionRef), {
                    notes: notes
                });
            } else {
                // Update the first existing document
                const existingNoteDoc = notesSnapshot.docs[0];
                await updateDoc(existingNoteDoc.ref, {
                    notes: notes
                });
            }
            
            console.log("Lesson changes saved successfully!");
    
        } catch (error) {
            console.error("Error saving lesson changes:", error);
        }
    };
    

    const handleClearErroredWordsClick = () => {
        if (selectedStudent && selectedObjective !== null && selectedLessonId) {
            handleClearErroredWords(
                erroredWords
            );
        }
    };

    const handleClearErroredWords = () => {
        setErroredWords([]);
    };


    //calculates the amount of passed lessons a student has for a current unit, runs based off of edit grade, pass, fail, ungraded values/buttons
    const recalculatePassedLessons = async (studentDocumentID, objectiveNum, requiredPassesToProgress) => {
        try {
            const lessonsCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${objectiveNum}`, 'lessons');
            const lessonsSnapshot = await getDocs(lessonsCollectionRef);
            const passedLessonsCount = lessonsSnapshot.docs.filter(doc => doc.data().pass === true).length;
    
            const objectiveDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${objectiveNum}`);
            await updateDoc(objectiveDocRef, { passedLessons: passedLessonsCount });
    
            const studentDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`);
            const studentDocSnapshot = await getDoc(studentDocRef);
            const studentData = studentDocSnapshot.data();
    
            if (passedLessonsCount >= requiredPassesToProgress) {
                if (studentData.currentObjective === objectiveNum) {
                    await updateDoc(studentDocRef, { currentObjective: studentData.currentObjective + 1 });
    
                    // Create a new document in the student's objectives subcollection
                    const newobjectiveNum = studentData.currentObjective + 1;
                    const newObjectiveDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${newobjectiveNum}`);
                    await setDoc(newObjectiveDocRef, {
                        objectiveNum: newobjectiveNum,
                        passedLessons: 0,
                        totalLessons: 0
                    });
                }
            } else {
                if (studentData.currentObjective === objectiveNum + 1) {
                    await updateDoc(studentDocRef, { currentObjective: studentData.currentObjective - 1 });
                }
            }
        } catch (error) {
            console.error("Error recalculating passed lessons:", error);
        }
    };
    
      
    
    
    // sets color on class progress chart cells
    const getColorCode = (studentCurrentObjective, objectiveNum) => {
        if (objectiveNum < studentCurrentObjective) {
            return '#44AF69';
        } else if (objectiveNum === studentCurrentObjective) {
            return '#F3C03B';
        } else {
            return '#AA4747';
        }
    };

    // opens individual student lesson views
    const handleSelectStudent = (student) => {
        setSelectedStudent(student);
        fetchStudentLessonData(student.id);
    };
    
    // Closes student view
    const handleClosePopup = () => {
        setSelectedStudent(null);
    };


    // shows loading symbol on page enter
    if (loading) {
        return <div className="lds-ellipsis" style={{position: 'absolute', left: '50%', top: '50%'}}><div></div><div></div><div></div><div></div></div>;
    }

    // displays an error on the page if any fetching fails
    if (error) {
        return <div>Error: {error.message}</div>;
    }


    // checks if the classroom has students, if not prompts the user to add a class/student
    if (!studentsData || studentsData.length === 0) {
        return (
            <div>
                <div>No students found!</div>
                <AddStudent
                    teacherDocumentID={teacherDocumentID}
                    setStudentsData={setStudentsData}
                />
            </div>
        );
    }

    
    // Adds unit number and total students in unit to classObjectivesData object
    const classObjectivesData = {};
    studentsData.forEach(student => {
        if (!classObjectivesData[student.currentObjective]) {
            classObjectivesData[student.currentObjective] = 1;
        } else {
            classObjectivesData[student.currentObjective]++;
        }
    });


    return (
        <div className='classDataContainer'>
            <div style={{alignSelf: 'flex-start', width: '90%', margin: '10px auto', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h1 style={{alignSelf: 'flex-start'}}>Welcome Back, {firstName}!</h1>
                <button style={{fontSize: '20px', padding: '8px 20px', backgroundColor: '#276FBF', color: 'white', borderRadius: '4px', border: 'none'}}>Export</button>
            </div>
            <QuickData 
                studentCount={studentCount}
            />
            <ClassStats 
                classObjectivesKeys={Object.keys(classObjectivesData)}
                classObjectivesValues={Object.values(classObjectivesData)}
                unitScores={scores}
                unitNames={names}
            />
            <StudentProgressChart
                studentsData={studentsData}
                objectivesData={objectivesData}
                getColorCode={getColorCode}
                handleSelectStudent={handleSelectStudent}
            />
            {selectedStudent && lessonObjectiveData &&(
                <StudentPopup
                    selectedStudent={selectedStudent}
                    lessonObjectiveData={lessonObjectiveData}
                    fetchLessonData={fetchLessonData}
                    setSelectedObjective={setSelectedObjective}
                    handleClosePopup={handleClosePopup}
                    lessonsData={lessonsData}
                    graded={graded}
                    setGraded={setGraded}
                    selectedLessonId={selectedLessonId}
                    nextLesson={nextLesson}
                    previousLesson={previousLesson}
                    currentLessonIndex={currentLessonIndex}
                    displayLesson={displayLesson}
                    currentObjective={currentObjective}
                    notes={notes}
                    setNotes={setNotes}
                    erroredWords={erroredWords}
                    setErroredWords={setErroredWords}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    handleSaveLessonChanges={handleSaveLessonChanges}
                    handleSaveLessonChangesClick={handleSaveLessonChangesClick}
                    handleClearErroredWordsClick={handleClearErroredWordsClick}  
                    percentageCorrect={percentageCorrect}
                    setPercentageCorrect={setPercentageCorrect}
                />
            )}

        </div>
    );
};

export default TeacherFetch;
