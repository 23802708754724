import React from 'react';
import '../../css/componentStyles.css';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './progressBar';
import ProfilePicture from '../../images/BabbleX.png';
import FlameIcon from '../../images/flame-icon.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faImage } from '@fortawesome/free-solid-svg-icons';
import FetchBadges from './badges';

library.add( faFire, faImage )


export default function Lessons({
    firstName = '',
    lastName = '',
    streak= '',
    teacherDocumentID = '',
    studentDocumentID = '',
    studentCurrentObjectiveNumber = '',
    currentObjectiveData = '',
	precedingObjectiveData = '',
    passedLessons,
    totalLessons,
    requiredPassesToProgress,
    curriculumName = '',
    profilePic,
    gradeLevel
    }){
    const navigate = useNavigate();

    console.log(studentCurrentObjectiveNumber)

    function toNextLesson (){
        console.log(studentDocumentID, teacherDocumentID)
        console.log(precedingObjectiveData);
        console.log(currentObjectiveData);
        navigate('/lessonSession', {
            state: { 
                studentID : studentDocumentID, 
                teacherID : teacherDocumentID, 
                studentCurrentObjectiveNumber : studentCurrentObjectiveNumber,
                passedLessons : passedLessons,
                currentObjectiveData : currentObjectiveData,
                precedingObjectiveData : precedingObjectiveData,
                totalLessons : totalLessons,
                requiredPassesToProgress : requiredPassesToProgress,
                curriculumName : curriculumName
            }});
    }

    let suffix = '';

    if (gradeLevel == 'k') {
        // do nothing
    } else if (gradeLevel == '1') {
        suffix = 'st';
    } else if (gradeLevel == '2') {
        suffix = 'nd';
    } else if (gradeLevel == '3') {
        suffix = 'rd';
    } else {
        suffix = 'th';
    }
    

    return (
        <>
            <div className='studentLessonsBody'>
                <div className='lessonsContainer'>
                    <div className='lessonCard'>
                        <h3>Objective {studentCurrentObjectiveNumber}</h3>
                        <div>
                            <p>Phoneme(s): {currentObjectiveData.phonemeFocus}</p>
                            <p><button onClick = {toNextLesson}> Go! </button></p>
                        </div>
                    </div>
                </div>

                <div className='studentStats'>
                    <h3>Objective {studentCurrentObjectiveNumber} Progress</h3>
                    <ProgressBar 
                        passedLessons={passedLessons}
                        requiredPassesToProgress={requiredPassesToProgress}
                    />
                </div>

                <div className='studentProfile'>
                    <img src={profilePic} className='profilePic' />
                    <h3>{firstName} {lastName}</h3>
                    <div className='studentDetails'>
                        <div>
                            <div className='streakContainer'>
                                <img src={FlameIcon} className='streakIcon' />
                                <p className='streakNum' id='streakNum' style={{padding: '0 5px'}}>{streak}</p>
                            </div>

                            <h4>Streak</h4>
                        </div>
                        <div>
                            <p className='gradeNum'>{gradeLevel}{suffix}</p>
                            <h4>Grade</h4>
                        </div>
                    </div>
                </div>

                <FetchBadges 
                    studentDocumentID = {studentDocumentID}
                    teacherDocumentID = {teacherDocumentID}
                />
            </div>
        </>
    )
}