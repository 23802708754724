import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.js';
import ProfilePic from '../../images/BabbleSleep.png';
import './sideBar.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBell, faHouse, faUser, faRightFromBracket, faGear, faCircleQuestion, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';



library.add( faArrowRight, faBell, faHouse, faUser, faRightFromBracket, faGear, faCircleQuestion, faArrowUpFromBracket );

export default function Sidebar ({firstName, lastName, classCode, teacherDocumentID}) {

    const [user, setUser] = useState("");
    const navigate = useNavigate();

    const logout = async () => { // signs user out
        
        await signOut(auth).then(
            setUser(''), navigate('/')
        );
        
    };

    function openNav() {
        document.getElementById("sidebar").style.width = "300px";
        document.getElementById("openBtn").style.display = "none";
        document.getElementById("closeBtn").style.display = "block";
    }
      
    function closeNav() {
        document.getElementById("sidebar").style.width = "0";
        document.getElementById("openBtn").style.display = "block";
        document.getElementById("closeBtn").style.display = "none";
    }

    return (
        <>
            <div id='sidebar' className='teacherDashSidebar' style={{width: '300px'}}>
                <span>
                    <div className="profile">
                        <div className="profileDiv1"><img src={[ProfilePic]} /><div><FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" style={{color: "#ffffff",}} /></div></div>
                        <div className="profileDiv2"><p>{firstName} {lastName}</p><p>Class Code: {classCode}</p></div>
                        <div className="profileDiv3"><Link to={`/teacherDash/notifications/${firstName}/${teacherDocumentID}/${lastName}/${classCode}`} state={{firstName: firstName, lastName: lastName, classCode: classCode}} style={{cursor: 'pointer'}}><FontAwesomeIcon icon="fa-solid fa-bell" className="notificationBell"/></Link></div>
                    </div>

                    <div className="links">
                        <h3 className="linkHeading">
                            <Link to={`/teacherDash/`} state={{firstName: firstName, lastName: lastName, classCode: classCode}} className="linkWithIcon">
                                <FontAwesomeIcon className="linkHeadingIcon" icon="fa-solid fa-house" />
                                Dashboard
                            </Link>
                        </h3>

                        <hr />

                        <h3 className="linkHeading">
                            <FontAwesomeIcon className="linkHeadingIcon" icon="fa-solid fa-user" />
                            Classroom
                        </h3>
                            <Link to={`/teacherDash/classroom/${firstName}/${teacherDocumentID}/${lastName}/${classCode}`} state={{firstName: firstName, lastName: lastName, classCode: classCode}} className="subLink">Class Roster</Link>
                            {/* <Link to={`/teacherDash/curriculum/${firstName}/${teacherDocumentID}/${lastName}/${classCode}`} state={{firstName: firstName, lastName: lastName, classCode: classCode}} className="subLink">Curriculum</Link> */}

                        <hr />

                        <h3 className="linkHeading">
                            <FontAwesomeIcon className="linkHeadingIcon" icon="fa-solid fa-book" />
                            Stories
                        </h3>
                            {/*<Link to='#' className="subLink">Assign Story</Link>*/}
                            <Link to={`/teacherDash/teacherLibrary/${firstName}/${teacherDocumentID}/${lastName}/${classCode}`} state={{firstName: firstName, lastName: lastName, classCode: classCode}} className="subLink">My Library</Link>
                    </div>
                </span>

                <div className="bottomLinks">
                        <p onClick={async () => {await logout()}}><FontAwesomeIcon className="bottomLinkIcon" icon="fa-solid fa-right-from-bracket" />Sign Out</p>
                        <p onClick={async () => {await navigate(`/teacherDash/settings/${firstName}/${teacherDocumentID}/${lastName}/${classCode}`)}}><FontAwesomeIcon className="bottomLinkIcon" icon="fa-solid fa-gear" />Settings</p>
                        <p><FontAwesomeIcon className="bottomLinkIcon" icon="fa-solid fa-circle-question" />Help</p>
                </div>
            </div>

            <div className="sidebarButtonContainer">
                <button id="openBtn" className='sidebarBtn' style={{display: 'none'}} onClick={openNav}><FontAwesomeIcon icon="fa-solid fa-arrow-right" className="sidebarBtnIcon"/></button>
                <button id="closeBtn" className='sidebarBtn' style={{display: 'block'}} onClick={closeNav}><FontAwesomeIcon icon="fa-solid fa-arrow-right" className="sidebarBtnIcon" style={{rotate: '180deg'}}/></button>
            </div>
        </>
    )
}