import { useState, useEffect } from "react";
import OpenAI from 'openai';
import { firestore, storage } from "../../firebase";
import { doc, getDoc, addDoc, getDocs, collection, setDoc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useLocation } from 'react-router-dom';
import SelectSetting from '../demo/selectSetting';
import CharacterSelect from "../demo/selectCharacter";
import Noise from '../../images/noise.png';
import './lessonSession.css';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong, faMicrophone, faShare, faReply, faCircleStop } from '@fortawesome/free-solid-svg-icons';
import Stop from '../../images/Stopsign.png';

import { useNavigate } from "react-router-dom";

library.add( faArrowRightLong, faArrowLeftLong, faMicrophone, faShare, faReply, faCircleStop );


export default function LessonSession() {

  const location = useLocation();
  const teacherDocumentID = location.state.teacherID;
  const studentDocumentID = location.state.studentID;
  const studentCurrentObjectiveNumber = location.state.studentCurrentObjectiveNumber;
  const currentObjectiveData = location.state.currentObjectiveData;
  const precedingObjectiveData = location.state.precedingObjectiveData;
  let passedLessons = location.state.passedLessons;
  let totalLessons = location.state.totalLessons;
  const curriculumName = location.state.curriculumName;

  // Session Variables
  const [selectedObjectiveNumber, setSelectedObjectiveNumber] = useState('');
  const [objectiveLoading, setObjectiveLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [sentences, setSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [openAIOutput, setOpenaiOutput] = useState('');

  // Audio transcript variables
  const [transcriptToSubmit, setTranscriptToSubmit] = useState("");
  const [storyContentToSubmit, setStoryContentToSubmit] = useState("");

  // Scoring variables
  let accuracy = 0;

  // Audio Recorder variables
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [contentLoaded, setContentLoaded] = useState(false);

  const navigate = useNavigate();

  // Image Gen variables
  const [prompt, setPrompt] = useState("");
  const [result, setResult] = useState("");
  const [imgLoading, setImgLoading] = useState('');

  //setting variables
  const [selectedSetting, setSelectedSetting] = useState('');

  //character variables
  const [selectedCharacter, setSelectedCharacter] = useState('');

  function toStudentDash (){
    navigate('/studentDash', { 
      state: { 
      studentID : studentDocumentID, 
      teacherID : teacherDocumentID, 
      studentCurrentObjectiveNumber : studentCurrentObjectiveNumber,
      passedLessons : passedLessons,
      currentObjectiveData : currentObjectiveData,
      precedingObjectiveData : precedingObjectiveData,
      curriculumName : curriculumName
  }});
}


  // *******************************************
  // Audio Transcription Functions and Variables
  // *******************************************

const {
  transcript,
  listening,
  resetTranscript,
  browserSupportsSpeechRecognition
} = useSpeechRecognition();

const startListening = () => {
  SpeechRecognition.startListening({ continuous: true });
}

const stopListening = async (audioURL) => {
  SpeechRecognition.stopListening();
  compareTexts(audioURL);

  resetTranscript();
  toStudentDash(); // Navigate to the student dashboard   
}

const writeLesson = async (totalLessons, audioURL) => {
  const sentencesDocRef = await setDoc(doc(firestore, `users`, `${teacherDocumentID}`, `students`, `${studentDocumentID}`, 'objectives', `objective${studentCurrentObjectiveNumber}`, `lessons`, `lesson${totalLessons + 1}`), {
    storyContent: sentences, 
    transcript: transcriptToSubmit || transcript,
    score: accuracy,
    audio: audioURL,
    pass: null,
    isGraded: false
  })
}


// *************************************
// Compare text Functions and Variables
// *************************************

const compareTexts = async (audioURL) => {
  let passCondition = 90;
  let newString = sentences.join(' ').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ");

  const sentencesString = newString.toLowerCase().split(/\s+/).filter(Boolean);
  const transcriptString = transcript.toLowerCase().split(/\s+/).filter(Boolean);

  let matchingWords = 0;

  sentencesString.forEach(word => {
    const matchedIndex = transcriptString.findIndex(w => w === word);
    if (matchedIndex !== -1) {
      matchingWords++;
      transcriptString.splice(matchedIndex, 1);
    }
  });

  const totalWords = sentencesString.length;
  const similarity = (matchingWords / totalWords) * 100;

  accuracy = similarity.toFixed(2);

  try {
    const checkForDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${studentCurrentObjectiveNumber}`);
    let docSnap = await getDoc(checkForDocRef);
    if(docSnap.exists()) { // Corrected this line
      console.log('if')
      const objectiveDocRec = await updateDoc(checkForDocRef, {
        totalLessons: parseInt(totalLessons) + 1 // Assuming totalLessons is a number, corrected the increment
      });
      writeLesson(totalLessons, audioURL);
    } else {
      console.log('else')
      const objectiveDocRec = await setDoc(checkForDocRef, {
        passedLessons: parseInt(passedLessons),
        objectiveNum: studentCurrentObjectiveNumber,
        totalLessons: parseInt(totalLessons) + 1
      });
      writeLesson(totalLessons, audioURL);
    }
  } catch (error) {
    console.log(error + ' catch error fired');
  }
  
}

  // *************************************
  // Audio Recorder Functions and Variables
  // *************************************
  
  const startRecording = () => {
    setRecording(true);
  };

  const stopRecordingAndUpload = async () => {
    setRecording(false);
    const audioBlob = await recorderControls.stopRecording();
    return audioBlob;
  };

  useEffect(() => {
    if (contentLoaded) {
      startRecording();
    }
  }, [contentLoaded]);

  const uploadAudio = async (blob) => {
    const storageRef = ref(storage, `audio/recordedLessons/${studentDocumentID}/objective${studentCurrentObjectiveNumber}/lesson${totalLessons + 1}.mp3`);
    const uploadTask = uploadBytesResumable(storageRef, blob);
    try {
      await uploadTask;
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      console.log("File available at", downloadURL);
      setAudioURL(downloadURL);
      stopListening(downloadURL); // Stop speech recognition
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  useEffect(() => {
    if (recording) {
      recorderControls.startRecording();
    } else {
      if (recorderControls.isRecording) {
        recorderControls.stopRecording();
      }
    }
  }, [recording]);

// Define handleFinish function
const handleFinish = () => {
  setRecording(false);
  const audioBlob = recorderControls.stopRecording();
  if (audioBlob) {
    uploadAudio(audioBlob); 
  } else {
    console.error("stopRecording() did not return a valid audioBlob.");
  }
};



  // ****************************************
  // Story Generation Variables and Functions
  // ****************************************

  const handleStorySubmit = async () => {
    setTextLoading(true);

    const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

    const phonemeFocusPreceding = precedingObjectiveData.map(obj => obj.phonemeFocus).join(", ");
    const objectiveNotesPreceding = precedingObjectiveData.map(obj => obj.objectiveNotes).join(", ");
console.log(phonemeFocusPreceding)
console.log(objectiveNotesPreceding)


const promptTemplate = `
  Please write a short, simple, and appropriate story for K-2 aged children that uses the following phonemic structures "${currentObjectiveData.phonemeFocus}", "${phonemeFocusPreceding}".
  Do not use any structures in the story that are not expressly provided above.
  The story itself, however, should have a high concentration of this phonemic structure: "${currentObjectiveData.phonemeFocus}".
  The story should be approximately 40 words long. Only include the contents of the story in your output.
  Please do not use any dialogue in the story, and avoid punctuation other than periods and commas.
  Include the concepts explained in the objective notes: "${objectiveNotesPreceding}". The objective number preceding the notes corresponds to each objective number provided above, to better explain how each phoneme focus is used.
  Take this user input for a character of a story: ${selectedCharacter} and this setting: ${selectedSetting.length > 0 ? selectedSetting : 'do not use a setting in the story'}
`;


    const stream = await openai.beta.chat.completions.stream({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: promptTemplate }],
      stream: true,
      max_tokens: 700
    });

    let generatedStory = '';

    stream.on('content', (delta, snapshot) => {
      setTextLoading(false);
      generatedStory += delta;
      setOpenaiOutput(generatedStory.trim());
      const storySentences = generatedStory.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.trim() + '.');
      setSentences([...storySentences]);
      console.log(promptTemplate)
    });
  };

  const handleNextSentence = () => {
    setCurrentSentenceIndex((prevIndex) => Math.min(prevIndex + 1, sentences.length - 1));
  };

  const handlePrevSentence = () => {
    setCurrentSentenceIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };



  // ****************************************
  // Image Generation Variables and Functions
  // ****************************************

  // GPT Account API Key
  const OPENAI_API_KEY = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

  const GenerateImage = async ( x ) => {
    setImgLoading(true);
    const res = await OPENAI_API_KEY.images.generate({
      model: "dall-e-3",
      prompt: x,
      n: 1,
      size: "1024x1024"
    });
    setImgLoading(false);
    setResult(res.data[0].url);
  };

  useEffect(() => {
    if (selectedCharacter && (selectedSetting || selectedSetting === "")) {
      const newPrompt = "Illustrate a scene or characters for a children’s book, using this character: " + selectedCharacter + (selectedSetting ? " and this setting: " + selectedSetting : "" + ".");
      setPrompt(newPrompt);
    } 
  }, [selectedCharacter, selectedSetting, prompt]);
  


  return (
    <div className="studentLessonContainer">
      {!!!currentObjectiveData && precedingObjectiveData ? (
        <div className='startCreatingBtn'>
          Loading Phonemic Structures
        </div>
      ) : (
        <>
          {!!!selectedCharacter ? (
            <div className="lessonSelectCharacter">
              <h3 className='lessonSelectHeader'>Select a Character!</h3>
              <CharacterSelect onSelectCharacter={setSelectedCharacter} />
            </div>
          ) : (
            <>
              {!!!selectedSetting /*&& studentCurrentObjectiveNumber >= 20*/? (
                <div className="lessonSelectHeader">
                  <h3 className='lessonSelectHeader'>Select a Setting!</h3>
                  <SelectSetting onSelectSetting={setSelectedSetting} />
                </div>
              ) : (
                <>
                  <div id="buttonContainer">
                    <button className='generateBtn' onClick={(e) => {handleStorySubmit(); GenerateImage(prompt); e.target.style.display = 'none'}}>Start Reading!</button>
                  </div>
                  {imgLoading ? (
                    <>
                      <h3 style={{fontSize: '30px', color: '#292F36'}}>Writing your story!</h3>
                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </>
                  ) : (
                    <>
                    {!result ? (
                      <></>
                    ) : (
                      <>
                      <div className="bookCover" style={{backgroundImage: `url(${Noise}`, backgroundSize: 'cover'}}>
                      <div className="bookPages Outer">
                      <div className="bookPages Inner">
                      <div onLoad={() => {startListening(); setContentLoaded(true)}} className='generatedContentSection'>
                        <div className="generatedImgArea" style={{backgroundImage: `url(${result})`, backgroundSize: 'cover', height: '50vh', backgroundPosition: 'center'}}>
                            <AudioRecorder
                              className = 'audioRecorderComponent'
                              style = {{display: 'none'}}
                              recorderControls={recorderControls}
                              showVisualizer={true}
                              onRecordingComplete={(blob) => {
                                recorderControls.stopRecording();
                                uploadAudio(blob);
                              }}
                            />
                            {recording && (
                              <div className="recordingIndicator">
                                  <FontAwesomeIcon icon="microphone" size="3x" color="red" />
                              </div>
                            )}
                            
                            <button onClick={handlePrevSentence} className="arrowBtn leftArrow" disabled={currentSentenceIndex === 0}><FontAwesomeIcon className='storyArrows' icon="fa-solid fa-reply" /></button>
                        </div>
                             <div className="generatedStoryArea" id="page">
                              <div id='flip' style={{display: 'none', border: '1px solid black'}}></div>
                            <p>{sentences[currentSentenceIndex]}</p>
                            <div>
                                <>
                                  {currentSentenceIndex == sentences.length - 1 ? (
                                      <>
                                        <button className='finishBtn' onClick={()=> {handleFinish()}} >
                                          <FontAwesomeIcon className='finishIcon' icon="fa-solid fa-circle-stop" />
                                        </button>

                                      </>
                                  ) : (
                                      <>
                                        <button onClick={handleNextSentence} className='arrowBtn rightArrow' disabled={currentSentenceIndex === sentences.length - 1}><FontAwesomeIcon className='storyArrows' icon="fa-solid fa-share" /></button>
                                      </>
                                  )}
                                </>
                            </div>
                        </div>
                      </div>
                      </div>
                      </div>
                      </div>
                    </> 
                    )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}