import React, { useState } from 'react';
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { firestore } from '../../firebase.js';
import { useNavigate, Link } from 'react-router-dom';
import './student.css';

const StudentSignIn = ({ onClassCodeSubmit }) => {
    const [classCode, setClassCode] = useState("");
    const [students, setStudents] = useState([]);
    const [error, setError] = useState("");
    const [classCodeSubmitted, setClassCodeSubmitted] = useState(false);
    const [userDocumentId, setUserDocumentId] = useState(null);
    const [objectiveNumber, setObjectiveNumber] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [currentObjective, setCurrentObjective] = useState("");
    const [classPrivacy, setClassPrivacy] = useState("");
    const [showAddStudentForm, setShowAddStudentForm] = useState(false);
    const navigate = useNavigate();

    const checkClassCode = async () => {
        try {
            const classCodeQuery = query(collection(firestore, 'users'), where('classCode', '==', classCode));
            const classCodeSnapshot = await getDocs(classCodeQuery);

            if (classCodeSnapshot.empty) {
                setError("No class found!");
            } else {
                const classDoc = classCodeSnapshot.docs[0];
                setClassPrivacy(classDoc.data().classPrivacy);

                const studentsQuery = query(collection(classDoc.ref, 'students'));
                const studentsSnapshot = await getDocs(studentsQuery);
                
                const studentsList = [];
                studentsSnapshot.forEach((doc) => {
                    studentsList.push({
                        id: doc.id,
                        teacherID: classDoc.id,
                        ...doc.data()
                    });
                });
                setStudents(studentsList);
                setClassCodeSubmitted(true);
                onClassCodeSubmit();
                setError("");
            }
        } catch (error) {
            console.error("Error checking class code: ", error.message);
        }
    };

    const handleStudentClick = async (student) => {
        try {
            setUserDocumentId(student.id);
            setFirstName(student.firstName);

            const objectivesQuery = query(collection(firestore, 'users', student.teacherID, 'students', student.id, 'objectives'));
            const objectivesSnapshot = await getDocs(objectivesQuery);
            let maxObjectiveNumber = -Infinity;

            objectivesSnapshot.forEach(objectiveDoc => {
                const objectivesData = objectiveDoc.data();
                if (objectivesData.objectiveNumber > maxObjectiveNumber) {
                    maxObjectiveNumber = objectivesData.objectiveNumber;
                }
            });

            setObjectiveNumber(maxObjectiveNumber);
            navigate('/studentDash', { state: { studentID: student.id, teacherID: student.teacherID }});
        } catch (error) {
            console.error("Error handling student click: ", error.message);
        }
    };

    const handleAddStudent = async () => {
        if (!firstName || !lastName) {
            setError("Please fill in all fields.");
            return;
        }

        try {
            const classCodeQuery = query(collection(firestore, 'users'), where('classCode', '==', classCode));
            const classCodeSnapshot = await getDocs(classCodeQuery);

            if (!classCodeSnapshot.empty) {
                const classDoc = classCodeSnapshot.docs[0];
                const studentsCollectionRef = collection(classDoc.ref, 'students');
                const newStudentRef = await addDoc(studentsCollectionRef, {
                    firstName,
                    lastName,
                    currentObjective: parseInt(currentObjective) // Convert currentObjective to a number
                });
                
                navigate('/studentDash', { state: { studentID: newStudentRef.id, teacherID: classDoc.id } });
            }
        } catch (error) {
            console.error("Error adding student: ", error.message);
            setError("Error adding student. Please try again.");
        }
    };

    const handleBack = () => {
        setClassCode("");
        setClassCodeSubmitted(false);
        setShowAddStudentForm(false);
    };

    return (
        <div className='signinPage'>
            {!classCodeSubmitted && (
                <div className='studentSigninContainer'>
                    <h3 className='studentSignInHeader'>Welcome back Students!</h3>
                    <input className='classCode' placeholder='Class Code' onChange={(event) => {
                        setClassCode(event.target.value.toUpperCase());
                    }} />
                    {error && <p className="error">{error}</p>}
                    <button className='submitClassCodeBtn' onClick={checkClassCode}>Go!</button>
                    <p>Not a student? <Link to='/teacherSignIn'>Click here!</Link></p>
                </div>
            )}
            {classCodeSubmitted && (
                <div>
                    <div className='studentListContainer'>
                        <div className='studentListHeader'>Find your Name!</div>
                        <ul className='studentList'>
                            {students.map((student) => (
                                <li key={student.id} onClick={() => handleStudentClick(student)}>
                                    {student.firstName} {student.lastName[0]}.
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button className='backButton' onClick={handleBack}>Back</button>
                    {classPrivacy === 'public' && (
                            <button className='addStudentBtn' onClick={() => setShowAddStudentForm(true)}>I'm New!</button>
                        )}
                    {error && <p className="error">{error}</p>}
                </div>
            )}
            {showAddStudentForm && (
                <div className='aignInAddStudentForm'>
                    <div>
                        <input 
                            type="text" 
                            className='studentSignInAddStudentInput'
                            placeholder="First Name" 
                            value={firstName} 
                            onChange={(e) => setFirstName(e.target.value)} 
                        />
                        <input 
                            type="text" 
                            className='studentSignInAddStudentInput'
                            placeholder="Last Name" 
                            value={lastName} 
                            onChange={(e) => setLastName(e.target.value)} 
                        />
                        <input 
                            type="number" 
                            className='studentSignInAddStudentInput'
                            placeholder="Current Objective" 
                            value={currentObjective} 
                            onChange={(e) => setCurrentObjective(e.target.value)} 
                        />
                    </div>
                    <button className='signInConfirmAddStudentBtn' onClick={handleAddStudent}>Confirm</button>
                </div>
            )}
            {userDocumentId && (
                <p className='welcomeMessage'>Welcome, {firstName}. You are on objective {objectiveNumber}</p>
            )}
        </div>
    );
};

export default StudentSignIn;
